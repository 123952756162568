/* Footer */

#footer {
	background-color: #232323;
	color: #fff;
	position: relative;
	padding: 40px 0 10px 0;
}

	#footer a {
		color: #fff;
	}
	
	#footer .tyre-marks {
		width: 100%;
		height: 14px;
		background: url('/homepage/images/tyre-marks-bg.png') center center repeat-x;
		position: absolute;
		top: -14px;
	}
	
	#footer h3 {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 24px;
	}
	
	#footer h4 {
		color: #fff;
	}
	
	#footer nav {
		margin: 25px 0 40px 0;
		
		@media (min-width: $lg-min) {
			margin: 25px 0 0 0;
		}
	}
	
	#footer nav,
	#footer nav a {
		font-size: 14px;
		
		@media (min-width: $sm-min) {
			
		}
		
		@media (min-width: $md-min) {
			font-size: 15px;
		}
		
		@media (min-width: $lg-min) {
			font-size: 17px;
		}
	}
	
		#footer nav li {
			margin-bottom: 8px;
		}
		
	#footer form {
		margin-bottom: 40px;
	}
	
	#footer p {
		font-size: 13px;
	}
	
#bottom-bar {
	padding: 40px 0 30px 0;
	background-color: #e1e1e1;
	color: #a0a0a0;
}

	#bottom-bar div {
		color: #a0a0a0;
	}
	
		#bottom-bar a {
			color: #7c7d74;
		}

	#bottom-bar .sponsors {
		text-align: center;
		
		@media (min-width: $md-min) {
			text-align: left;
		}
	}

	#bottom-bar .copyright {
		text-align: center;
		padding-top: 15px;
		font-size: 12px;
		
		@media (min-width: $md-min) {
			padding-top: 30px;
			text-align: right;
			font-size: 14px;
		}
		
		@media (min-width: $lg-min) {
			padding-top: 35px;
			font-size: 16px;
		}
	}