/* Slide Out */

#drawer-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: 0.3s;
}
	
#mobile-menu-wrapper {
	position: relative;
	z-index: 10;
	opacity: .95;
}

	.slideout-menu {
		background-color: #1D1F20;
		background-image: linear-gradient(145deg, #1D1F20, #404348);
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		min-height: 100vh;
		overflow-y: scroll;
		height: 100%;
		width: 0;
		z-index: 1;
		overflow-x: hidden;
		overflow-y: auto;
		transition: 0.3s;
		box-shadow: 0px 0px 30px 00px rgba(0, 0, 0, 0.50);
		
		.close-menu {
			position: absolute;
			top: 0;
			right: 20px;
			font-size: 25px;
			transition: 0.1s;
			opacity: 1;
			line-height: 1em;
			
			i {
				color: #fff;
			}
		
		}
	}
	
	.menu a {
	  color: #fff !important;
	}
	
	.menu a:hover {
	  text-decoration: underline;
	}
	
	.menu li.no-link {
		color: #aaa;
		font-weight: 600;
	}
	
	.menu-section {
		position: relative;
		margin: 25px 0;
		width: 250px;
	}
	
		.menu-section.search-mobile-wrapper {
			padding: 0 20px;
			margin-top: 10px;
			margin-bottom: 0;
		}
		
			.menu-section.search-mobile-wrapper #search-mobile {
				padding-top: 30px;
			}
		
		#search-mobile button.btn {
			line-height: 1.44em;
		}
		
			#search-mobile button.btn:hover {
				color: #fff;
				cursor: pointer;
			}

	.menu-section-title {
	  text-transform: uppercase;
	  color: #fff !important;
	  font-size: 20px;
	  letter-spacing: 1px;
	  padding: 0 20px;
	  margin:0;
	}
	
	.menu-section-list {
	  padding:0;
	  margin: 10px 0;
	  list-style:none;
	}
	
		.menu-section-list li.active {
			font-weight: 700;
		}
	
	.menu-section-list a,
	.menu-section-list li.no-link {
	  display: block;
	  padding: 10px 20px;
	}
	
	.menu-section-list a:hover {
	  background-color: rgba(255, 255, 255, 0.1);
	  text-decoration: none;
	  color: #fff;
	}