/* Splash and Find Holiday Search (Tablet) */

#splash {
	position: relative;
	
	.ribbon {
		position: absolute;
		top: 0;
		left: 15px;
		transition: box-shadow .4s;
		
		
		.tyre {
			width: 100%;
			height: 30px;
			background-size: contain;
			background-position: top center;
			background-repeat: no-repeat;
			display: none;
		}
		
		.ribbon-inner {
			padding: 30px 20px 20px 20px;
			font-size: 16px;
			line-height: 1.2em;
			transition: padding .4s;
			
			@media (min-width: $sm-min) {
				font-size: 26px;
			}
			
			@media (min-width: $md-min) {
				font-size: 36px;
			}
			
			span {
				color: #fff;
				font-family: Veneer;
				
				&.save {
					font-size: 210%;
				}
				
				&.percent {
					font-size: 150%;
				}
				
				&.subline {
					font-size: 110%;
				}
			}
		}
		
		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.4);
			
			.ribbon-inner {
				padding: 60px 20px 20px 20px;
			}
			
		}
		
	}
}

	#splash .btn.btn-action:hover,
	#splash .btn.btn-action:focus,
	#findHolidayBar .btn.btn-action:hover,
	#findHolidayBar .btn.btn-action:focus {
		background-color: #232323;
		border-color: #232323;
		color: #fff;
	}

	#splash > .container {
		position: relative;
	}
	
	#splash .form-wrapper {
		min-height: 455px;
		padding-top: 100px;
		padding-bottom: 10%;
		
		@media (min-width: $sm-min) {
			min-height: 414px;
			padding-top: 0;
			padding-bottom: 0;
		}
		
		@media (min-width: $md-min) {
			min-height: 564px;	
		}
		
		@media (min-width: $lg-min) {
			min-height: 640px;
		}
	}
	
		#splash .form-wrapper .inner {
    		
			@media (min-width: $sm-min) {
				position: absolute;
				bottom: 25%;
            }
            
			@media (min-width: $md-min) {
                bottom: 16%;
            }
			
    		@media (min-width: $lg-min) {
                bottom: 16%;
            }
			
		}
		
		#splash .search-intro {
			color: #fff;
			text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.80);
			margin-bottom: 10px;
			text-transform: uppercase;
			font-size: 28px;
			
			@media (min-width: 510px) {
				font-size: 43px;
			}
		}
		
		#findHolidayBar .search-intro {
			margin-bottom: 10px;
			font-size: 20px;
			font-weight: 600;
			margin-top: 10px;
			
			@media (min-width: 510px) {
				margin-top: 0;
				font-size: 22px;
			}
		}
		
		#splash button,
		#findHolidayBar button {
			text-transform: uppercase;
		}
		
		#splash .form-control,
		#splash .input-group,
		#findHolidayBar .form-control,
		#findHolidayBar .input-group {
			width: 100%;
			font-size: 13px;
		}
		
		#splash .form-control,
		#splash button,
		#findHolidayBar .form-control,
		#findHolidayBar button {
			box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.5);
		}
		
		#splash .form-control.calendar,
		#findHolidayBar .form-control.calendar {
			background-image: url('/homepage/images/calendar-icon.png');
			background-position: center right 5px;
			background-repeat: no-repeat;
		}
		
		#splash select.form-control,
		#findHolidayBar select.form-control {
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			
			background: url('/homepage/images/select-bg.png') #fff center right no-repeat;
			padding-right: 50px;
		}
		
		#splash select.form-control {
			border: none;
		}
		
		#findHolidayBar select.form-control {
			border-bottom: none;
			border-right: none;
		}
		
			#splash select.form-control:hover,
			#findHolidayBar select.form-control:hover {
				background: url('/homepage/images/select-bg-hover.png') #fff center right no-repeat;
			}
			
		#splash .form-control,
		#findHolidayBar .form-control {
			border-radius: 0;
		}
		
		#splash .form-control,
		#splash .form-group input::placeholder {
			font-size: 16px;
			
			@media (min-width: $sm-min) {
				font-size: 11px;
			}
			
			@media (min-width: $md-min) {
				font-size: 16px;
			}
			
		}
		
		/* Set Input Size By Device */
		#splash .slide0,
		#splash .slide1,
		#findHolidayBar .slide0,
		#findHolidayBar .slide1 {
			width: 100%;
			
			@media (min-width: $sm-min) {
				width: 200px;
			}
			
			@media (min-width: $md-min) {
				width: 274px;
			}
		}
		
		#splash .slide2,
		#splash .slide3,
		#findHolidayBar .slide2,
		#findHolidayBar .slide3 {
			width: 49%;
			float: left;
			
			@media (min-width: $sm-min) {
				width: 110px;
				float: none;
			}
			
			@media (min-width: $md-min) {
				width: 141px;
			}
		}
		
		#splash .slide3,
		#findHolidayBar .slide3 {
			float: right;
			
			@media (min-width: $sm-min) {
				float: none;
			}
		}
		
		#findHolidayBar .slide3 {
			
			@media (min-width: $sm-min) {
				width: 100px;
			}
			
			@media (min-width: $md-min) {
				width: 141px;
			}
			
		}
		
		#splash .submit-expanded,
		#findHolidayBar .submit-expanded {
			clear: both;
			color: #fff;
			margin-bottom: 0;
		}
		
		
		// Hide Inputs 1 onwards on Mobile
		
		#splash .slide1,
		#splash .slide2,
		#splash .slide3,
		#splash .submit-expanded {
			display: none;
			
			@media (min-width: $sm-min) {
				display: inline-block !important;
			}
		}
		
	#splash .slide-controls .control,
	#findHolidayBar .slide-controls .control {
		top: 40%;
		position: absolute;
		font-size: 70px;
		color: #fff;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.80);
		opacity: 0.5;
		z-index: 4;
	}
	
		#splash .slide-controls .control:hover,
		#findHolidayBar .slide-controls .control:hover {
			opacity: 1;
		}
	
		#splash .slide-controls .prev,
		#findHolidayBar .slide-controls .prev {
			left: 0;
		}
	
		#splash .slide-controls .next,
		#findHolidayBar .slide-controls .next {
			right: 0;
		}
		
	/* Info (Mobile) */
	
	#splash .info-xs {
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 4;
	}
	
		#splash .info-xs a,
		#splash .info-xs .toggle {
			color: #fff;
		}
		
		#splash .info-xs .toggle,
		#splash .info-xs .more {
			display: block;
		}
		
		#splash .info-xs .more {
			padding: 5px 15px 5px 50px;
			min-height: 32px;
		}
		
			#splash .info-xs.expanded .more {
				background-color: rgba(0, 0, 0, 0.5);
			}
			
			#splash .info-xs.collapsed .more a {
				display: none;
			}
		
		#splash .info-xs .toggle {
			background-color: #000;
			width: 22px;
			height: 22px;
			text-align: center;
			padding-top: 2px;
			border-radius: 50%;
			position: absolute;
			left: 15px;
			top: 5px;
		}
	
	
	/* Info (Tablet Upwards) */
	
	@media (min-width: $sm-min) {
	
		#splash .info {
			position: absolute;
			right: 15px;
			text-align: right;
			bottom: 15px;
			z-index: 2;
			top: 10%;
			bottom: auto;
		}
		
			#splash .info .toggle {
				display: inline-block;
			}
		
			#splash .info a,
			#splash .info .toggle {
				color: #fff;
			}
			
			#splash .info .toggle {
				padding: 0px 9px;
				font-size: 18px;
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
			}
			
				#splash .info.collapsed .toggle {
					background-color: rgba(0, 0, 0, 0.8);
				}
				
					#splash .info.collapsed .more {
						display: none;
					}
			
				#splash .info .toggle:hover {
					color: #000;
					background-color: #fff;
				}
			
			#splash .info.expanded {
				padding-left: 15px;
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
				background-color: rgba(0, 0, 0, 0.8);
			}
			
			#splash .info .more {
				display: inline-block;
				font-size: 12px;
				line-height: 20px;
				position: relative;
				top: -2px;
				padding-right: 20px;
			}
		
	}
	
	
	/* Full Video */
	
	.play-full {
		position: absolute;
		right: 30px;
		text-align: right;
		bottom: 15px;
		z-index: 2;
		top: 10%;
		bottom: auto;
		
		@media (min-width: $sm-min) {
			right: 15px;
		}
		
		.inner {
			background-color: #232323;
			color: #fff;
			position: relative;
			padding: 6px 40px 6px 12px;
			
			span {
				font-family: Veneer;
				font-size: 19px;
			}
			
			.play-button {
				position: absolute;
				right: -20px;
				top: -2px;
				width: 44px;
				height: 44px;
				background-color: #fff;
				color: #232323;
				text-align: center;
				border-radius: 50%;
				line-height: 52px;
				
				i {
					font-size: 29px;
					padding-left: 5px;
				}
			}
			
		}
		
		a:hover {
			text-decoration: none;
			
			.inner {
				opacity: 0.8;
			}
		}
		
	}
	
	#full-video {
		max-width: none;
		
		video {
			width: 100%;
			height: auto;
			
			&:focus {
				outline: none;
			}
		}
		
	}
	
		
	/* Backstretch Nav */
	
	#splash .backstretch-nav {
		position: absolute;
		bottom: 20px;
		text-align: center;
		width: 100%;
		z-index: 2;
	}
	
		#splash .backstretch-nav a {
			color: #fff;
			text-shadow: 1px 1px 1px black;
			cursor: pointer;
		}
		
		#splash .backstretch-nav a i:before {
			content: "\f10c";
			font-family: FontAwesome;
		}
		
			#splash .backstretch-nav a.active i:before,
			#splash .backstretch-nav a:hover i:before {
				content: "\f111";
			}
			
			
/* Splash Video Background */

#splash.video {
	position: relative;
}

	#video-viewport { 
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
		overflow: hidden; 
		z-index: -1; 
	}
	
	#video-viewport video { 
		display: block;
		 width: 100%; 
		 height: auto; 
		}
	
	.fullsize-video-bg { 
		height: 100%;
		overflow: hidden;
	}
	
	.fullsize-video-bg:before { 
		content: ""; 
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		z-index: 0; 
	}
	
	.fullsize-video-bg:after { 
		content: ""; 
		background-size: 3px 3px; 
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		z-index: 1; 
	}
	
	.fullsize-video-bg .inner { 
		display: table; 
		width: 100%; 
		max-width: 24em; 
		height: 100%; 
		margin: 0 auto; 
		padding: 0; 
		position: relative; 
		z-index: 2; 
		text-shadow: 0 1px 5px rgba(0,0,0,.5); 
	}
	
	.fullsize-video-bg .inner > div { 
		text-align: center; 
		display: table-cell; 
		vertical-align: middle; 
		padding: 0 2em; 
	}
	
	
	#splash.video .container.relative {
		position: relative;
		z-index: 3;
	}
	
	@media (min-width: $lg-min) {
		
		$aspect-padding: 50%;
		
		/* Other aspect ratios to try:
		* 56.25% = 16:9
		* 75% = 4:3
		* 66.66% = 3:2
		* 62.5% = 8:5
		*/
		
		#splash.video .aspect {
			width: 100%;
			padding-bottom: $aspect-padding;
			position: relative;
		}
		
		#splash.video .aspect > .aspect-inner {
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0;
		}
		
		#splash.video .container.relative {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
		
	}
	
	@media (min-width: 1355px) {
		
		#splash.video .aspect,
		#splash.video .aspect > .aspect-inner {
			height: 680px;
			padding-bottom: 0;
		}
		
	}


/* Roundel */

.roundel {
	position: absolute;
	bottom: 35px;
	right: 15px;
	transition: transform 0.4s;
	
	@media (min-width: $sm-min) {
		top: -110px;
		left: 0;
		right: auto;
		bottom: auto;
	}
	
	img {
		width: 100px;
		height: auto;
	}
	
}

	.roundel:hover {
		transform: scale(1.1) rotate(-12deg);
		cursor: pointer;
	}
	
	
	
/* Call Out */

#splash .call-out {
	font-family: Veneer;
	color: #fff;
	font-size: 11vw;
	position: absolute;
	left: 0;
	right: 0;
	top: 45%;
	text-align: center;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
	z-index: 3;
	
	&:hover {
		text-decoration: none;
	}
	
	
	/*
	animation-name: calloutanimation;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  transform: translateZ(0);
  text-rendering: optimizeLegibility;
  */
  
  @media (min-width: $sm-min) {
	  top: 31%;
	  font-size: 6vw;
	  left: 0;
		right: 0;
	}
	
	@media (min-width: $md-min) {
	  top: 38%;
	}
	
	@media (min-width: $lg-min) {
	  font-size: 76px;
	}
}

/*
@keyframes calloutanimation {
  from {
	  font-size: 5vw;
	  top: 38%;
	}
  to {
	  font-size: 7vw;
	  top: 36%;
	}
}
*/