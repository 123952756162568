/* Intro */

#intro {
	background-color: #232323;
	color: #fff;
	padding: 25px 0;
	margin-top: -1px;
}

	#intro h1 {
		font-weight: 600;
		font-size: 22px;
		margin-bottom: 20px;
		margin-top: 10px;
		
		@media (min-width: $sm-min) {
			font-size: 33px;
		}
	}
	
	#intro p {
		font-size: 15px;
		font-weight: 300;
		
		@media (min-width: $md-min) {
			font-size: 16px;
		}
	}
	

$box-height-xs: 600px;
$box-height-sm: 600px;
$box-height-md: 600px;
$box-height-lg: 600px;
$box-gutter: 30px;


/* Best Sellers */

#best-sellers {
	padding: 60px 0 30px 0;
	
	@media (min-width: $sm-min) {
		padding: 60px 0 30px 0;
	}
	
	@media (min-width: $md-min) {
		padding: 60px 0 0 0;
	}
}
	
	#best-sellers h2 {
		font-size: 38px;
		text-align: center;
		margin-top: 0;
		margin-bottom: 20px;
		
		@media (min-width: $sm-min) {
			margin-bottom: 40px;
			font-size: 48px;
		}
	}

#best-sellers .box {
	display: block;
	color: #fff;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

	#best-sellers .box.text-black {
		color: #000;
		text-shadow: none;
	}

	#best-sellers .box-full {
		width: 100%;
		height: $box-height-lg/2;
		
		@media (min-width: $sm-min) {
			height: $box-height-lg + $box-gutter;
		}
	}
	
	#best-sellers .box-half {
		width: 100%;
		height: $box-height-lg / 2;
	}
	
		#best-sellers .box-half.first {
			margin-bottom: $box-gutter;
		}
	
	#best-sellers .box .background {
		background-position: top center;
		position: relative;
	}
	
		#best-sellers .video .box .background {
			background-position: top center;
			background-size: cover;
			border-bottom: 20px solid #000;
			height: ($box-height-xs / 2) - 95px;
			
			@media (min-width: $sm-min) {
				height: ($box-height-sm / 2) - 75px;
			}
			
			@media (min-width: $md-min) {
				height: ($box-height-md / 2) - 80px;
			}
			
			@media (min-width: $lg-min) {
				height: ($box-height-lg / 2) - 75px;
			}
		}
	
	#best-sellers .overlay {
		padding: 5% 7%;
		position: absolute;
		top: 0;
	}
	
	#best-sellers .video {
		margin-bottom: $box-gutter;
		
		@media (min-width: $sm-min) {
			margin-bottom: 0;	
		}
	}
	
		#best-sellers .video .background {
			background-color: #000;
		}
		
			#best-sellers .video .overlay {
				padding: 2% 4%;
				position: relative;
				background-color: #000;
				max-height: 95px;
		}
		
	$play-width: 48px;
		
	#best-sellers .play {
		background-color: rgba(255, 255, 255, 0.5);
		padding: 10px 15px;
		font-size: 20px;
		border-radius: 50%;
		text-align: center;
		width: $play-width;
		height: $play-width;
		display: inline-block;
		color: #313131;
		text-shadow: none;
		position: absolute;
		left: 50%;
		margin-left: -($play-width/2);
		top: 50%;
		margin-top: -($play-width/2);
	}
	
	#best-sellers h3 {
		margin: 0;
		font-size: 28px;
		margin-bottom: 5px;
		margin-top: 5px;
		
		@media (min-width: $sm-min) {
			font-size: 28px;
			margin-top: 5px;
		}
		
		@media (min-width: $md-min) {
			font-size: 36px;
			margin-top: 0;
		}
		
	}
	
		#best-sellers h3 i {
			margin-left: 5px;
		}
		
	
	#best-sellers p {
		font-size: 14px;
		margin-bottom: 5px;
		
		@media (min-width: $sm-min) {
			margin-bottom: 5px;
		}
		
		@media (min-width: $md-min) {
			font-size: 16px;
			margin-bottom: 0;
		}
	}
		
	#best-sellers .text-bottom {
		@media (min-width: $sm-min) {
			display: table;
		}
	}
	
		#best-sellers .text-bottom .overlay {
			@media (min-width: $sm-min) {
				display: table-cell;
				vertical-align: bottom;
			}
		}
		
	#best-sellers .text-right-sm {
		@media (min-width: $sm-min) {
			text-align: right;	
		}
	}
	
	#bestModal .modal-content {

	}
	
	#bestModal h4 {
		font-size: 26px;
	}
	
	#bestModal .videoWrapper {
		position: relative;
		padding-bottom: 54.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;
	}
	
		#bestModal .videoWrapper iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	
	#bestModal .modal-header {
		border-bottom: none;
		padding-bottom: 0;
	}
	
	#bestModal .modal-header button {
		opacity: 1;
	}
		
	#bestModal .modal-footer {
		text-align: left;
		border-top: none;
		padding-top: 0;
		font-weight: 600;
	}
	
		#bestModal .modal-footer a {
		}
	
	
/* CTAs */

#ctas {
	padding: 25px 0 0 0;
	
	@media (min-width: $md-min) {
		padding: 50px 0 30px 0;
	}
	
	@media (min-width: $lg-min) {
		padding: 60px 0 30px 0;
	}
}

	#ctas h2 {
		font-size: 38px;
		text-align: center;
		margin-top: 0;
		margin-bottom: 20px;
		
		@media (min-width: $sm-min) {
			margin-bottom: 20px;
			font-size: 48px;
		}
		
		@media (min-width: $md-min) {
			margin-bottom: 40px;
		}
	}

	#ctas .ctas-4 .box,
	#ctas .ctas-8 .box {
		height: 240px;
		
		@media (min-width: $sm-min) {
			height: 240px;
		}
		
		@media (min-width: $md-min) {
			height: 240px;
		}
		
		@media (min-width: $lg-min) {
			height: 260px;
		}
	}
	
	#ctas .ctas-6 .box {
		height: 200px;
		
		@media (min-width: $sm-min) {
			height: 200px;
		}
		
		@media (min-width: $md-min) {
			height: 200px;
		}
		
		@media (min-width: $lg-min) {
			height: 220px;
		}
	}

	#ctas .cta {
		margin-bottom: 30px;
	}
	
		#ctas .cta a {
			display: block;
			position: relative;
		}
	
		#ctas .cta h3 {
			position: absolute;
			width: 100%;
			color: #fff;
			margin: 0;
			top: 0;
			text-align: left;
			font-size: 20px;
			font-weight: 600;
			padding: 10px 15px;
		}
		
		#ctas .cta img {
			width: 100%;
		}


		
/* Benefits */

h2.big {
	text-align: center;
	color: #000;
	font-size: 8vw;
	margin-top: 0rem;
	margin-bottom: 2rem;
	
	@media (min-width: $sm-min) {
		font-size: 52px;
	}
}

#benefits {
	
	padding: 60px 0 0 0;
	
	@media (min-width: $sm-min) {
		padding: 80px 0 80px 0;
	}
	
	&.abovetestimonials {
		
		padding: 20px 0 0 0;
	
		@media (min-width: $sm-min) {
			padding: 20px 0 80px 0;
		}
		
	}
	
}

	#benefits > .container {	
		padding: 0;
		margin: 0;
		width: 100%;
		
		@media (min-width: $sm-min) {
			padding-right: 15px;
		    padding-left: 15px;
		    margin-right: auto;
		    margin-left: auto;
		    width: 750px;
		}
		
		@media (min-width: $md-min) {
			width: 970px;
		}
		
		@media (min-width: $lg-min) {
			width: 1170px;
		}
		
	}

#benefits .box {
	display: block;
	color: #fff;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

	#benefits .box.text-black {
		color: #000;
		text-shadow: none;
	}

	#benefits .box-full {
		width: 100%;
		height: $box-height-lg/2;
		
		@media (min-width: $sm-min) {
			height: $box-height-lg;
		}
	}
	
	#benefits .box-half {
		width: 100%;
		height: $box-height-lg / 2;
	}
	
	#benefits .box .background {
		background-position: top center;
	}
	
	#benefits .overlay {
		padding: 5% 7%;
	}
	
		#benefits h3 {
			margin: 0;
			font-size: 36px;
			margin-bottom: 2px;
			
			@media (min-width: $sm-min) {
				font-size: 26px;
			}
			
			@media (min-width: $md-min) {
				font-size: 36px;
			}
		}
		
			#benefits h3 i {
				margin-left: 5px;
			}
		
		#benefits p {
			font-size: 14px;
			
			@media (min-width: $md-min) {
				font-size: 16px;
			}
		}
		
	#benefits .text-bottom {
		@media (min-width: $sm-min) {
			display: table;
		}
	}
	
		#benefits .text-bottom .overlay {
			@media (min-width: $sm-min) {
				display: table-cell;
				vertical-align: bottom;
			}
		}
		
	#benefits .text-right-sm {
		@media (min-width: $sm-min) {
			text-align: right;	
		}
	}
	
	
	
	
/* Testimonials */

#testimonials {
	padding: 30px 0 10px;
	color: #fff;
	
	@media (min-width: $sm-min) {
		padding: 50px 0;
	}
}

	#testimonials .inner {
		padding: 40px 0;
		
		@media (min-width: $sm-min) {
			padding: 30px 9%;
		}
		
		@media (min-width: $lg-min) {
			padding: 30px 10%;
		}
	}
	
	#testimonials .carousel-control {
		background: none;
		font-size: 100px;
	}
	
	#testimonials .symbol {
		position: absolute;
		left: 0;
		top: -40px;
			
		@media (min-width: $sm-min) {
			left: -60px;
			top: -30px;
		}
		
	}
	
		#testimonials .symbol img {
			width: 33px;
			height: auto;
			
			@media (min-width: $sm-min) {
				width: 43px;
			}
		}
	
	#testimonials .quote {
		font-size: 22px;
		font-style: italic;
		margin-bottom: 20px;
		position: relative;
		font-weight: 300;
		
		@media (min-width: $sm-min) {
			font-size: 26px;
		}
		
		@media (min-width: $md-min) {
			font-size: 31px;
		}
	}
	
	#testimonials cite {
		font-size: 13px;
		font-style: normal;
		
		@media (min-width: $sm-min) {
			font-size: 16px;
		}
	}
	
		#testimonials cite a {
			color: #fff;
		}

	
/* Promo */

#promo {
	
	padding: 60px 0 40px 0;
		
	@media (min-width: $sm-min) {
		padding: 60px 0 20px 0;
	}
	
	@media (min-width: $md-min) {
		padding: 60px 0;
	}
	
	&.abovetestimonials {
		padding: 20px 0 40px 0;
		
		@media (min-width: $sm-min) {
			padding: 20px 0 40px 0;
		}
		
		@media (min-width: $md-min) {
			padding: 20px 0 40px 0;
		}
	
	}
}

	#promo img {
		width: 100%;
	}

	#promo h2 {
		font-size: 25px;
		color: #000;
		margin: 0 0 0 0;
		padding: 5px 0 5px 5px;
		
		@media (min-width: $sm-min) {
			font-size: 25px;
		}
		
		@media (min-width: $md-min) {
			font-size: 21px;
		}
		
		@media (min-width: $lg-min) {
			font-size: 25px;
		}
	}
	
		#promo a h2:after {
			font-family: FontAwesome;
			content: '\f105';
			margin-left: 10px;
			color: #868686;
		}
	
	#promo .box {
		height: 235px;
		
		@media (min-width: $sm-min) {
			height: 215px;
		}
		
		@media (min-width: $md-min) {
			height: 140px;
		}
		
		@media (min-width: $lg-min) {
			height: 175px;
		}
	}
	
	#promo .spacer {
		height: 25px;
	}
	
		#promo .col1 .spacer {
			height: 22px;
		}
		
		#promo .col3 .spacer {
			height: 15px;
			
			@media (min-width: $lg-min) {
				height: 20px;
			}
		}
	
	#promo .videos {
		display: block;
		border-bottom: 10px solid #000;
	}
	
		#promo .videos h2 {
			background-color: #000;
		}
	
		#promo .videos h2,
		#promo .videos h2:after {
			color: #fff;
		}
		
		#promo .videos img {
			border-bottom: 10px solid #000;
		}
		
		#promo .videos .box {
			height: 165px;
			
			@media (min-width: $sm-min) {
				height: 195px;
			}
			
			@media (min-width: $md-min) {
				height: 110px;
			}
			
			@media (min-width: $lg-min) {
				height: 130px;
			}
		}
		
		$play-width: 60px;
	
		#promo .play {
			background-color: rgba(255, 255, 255, 0.8);
			padding: 14px 10px 5px 8px;
			font-size: 26px;
			border-radius: 50%;
			text-align: center;
			width: $play-width;
			height: $play-width;
			display: inline-block;
			color: #313131;
			text-shadow: none;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -($play-width/2) - 5px;
			margin-top: -($play-width/2) - 5px;
			z-index: 2;
		}
		
			#promo .play:hover {
				background-color: rgba(255, 255, 255, 1);
			}
		
			#promo .play i {
				position: relative;
				left: 3px;
			}
		
	#promo .col:nth-child(2n+1){
		@media (min-width: $sm-min) {
			clear: both;
		}
		
		@media (min-width: $md-min) {
			clear: none;
		}
	}
	
	#promo .col {
		margin-bottom: 30px;
	}
	
	#promo .guaranteed {
		position: relative;
		width: 100%;
		display: block;
	}
		
		#promo .guaranteed img.overlay {
			position: absolute;
			width: 80px;
			height: auto;
			bottom: -10px;
			left: 20px;
			z-index: 2;
		}
	
		
/* News */

#news {
	padding-bottom: 50px;
	
	@media (min-width: $sm-min) {
		padding-bottom: 70px;
	}
}

	#news h2 {
		font-size: 30px;
		font-size: 8.5vw;
		margin-bottom: 20px;
		margin-top: 0;
		
		@media (min-width: $sm-min) {
			text-align: center;
			font-size: 40px;
		}
	}
	
		#news a h2:after {
			font-family: FontAwesome;
			content: '\f105';
			margin-left: 10px;
		}

	#news .post {
		margin-bottom: 30px;
	}
	
		#news h3 {
			color: #000;
			margin-top: 10px;
			margin-bottom: 5px;
			font-size: 18px;
			
			@media (min-width: $sm-min) {
				margin-top: 20px;
				margin-bottom: 10px;
			}
			
			@media (min-width: $sm-min) {
				font-size: 24px;
			}
			
		}
		
		#news .box {
			height: 220px;
			display: block;
			
			@media (min-width: $sm-min) {
				height: 150px;
			}
			
			@media (min-width: $md-min) {
				height: 190px;
			}
			
			@media (min-width: $lg-min) {
				height: 220px;
			}
		}

		#news .image {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
		}
		
		#news p {
			font-size: 14px;
			
			@media (min-width: $sm-min) {
				font-size: 15px;
			}
			
			@media (min-width: $md-min) {
				font-size: 17px;
			}
		}
	
/* HFH Modal */

#hfhModal {
	
	p,
	h3,
	label {
		color: #313131;
	}
	
	.modal-header {
		padding-top: 20px;
		color: #fff;
	}
	
		.modal-header .close {
			color: #313131;
			opacity: 1;
			background: #fff;
			border-radius: 50%;
			box-shadow: 4px 4px 18px -4px black;
			padding: 5px 7px 6px 8px;
			font-size: 14px;
			text-align: center;
		}
		
			.modal-header .close:hover {
				color: #fff;
				background-color: #313131;
			}
		
		
	
	.modal-body {
		margin: 0;
		border-bottom: none;
	}
	
		.modal-body .inner {
			padding: 10px 15px;
		}
	
	.modal-footer {
		border-top: none;
		box-shadow: none;
		background-color: #fff;
	}
	
	.modal-header {
		border-bottom: none;
	}
	
	.modal-body {
		padding-top: 0;
	}
	
		h3 {
			margin-top: 0;
			font-size: 24px;
			font-weight: 400;
		}
		
		label {
			font-weight: 400;
		}
	
	.modal-footer {
		padding-left: 30px;
		padding-right: 30px;
	}
	
	.btn {
		border: none;
	}	
	
	.form-group {
		padding-left: 20px;
	}

}


/* Passions */

#passions {
	margin: 6rem 0 2rem 0;
	
	h2 {
		margin-bottom: 3rem;
	}
	
	.passion {
		margin-bottom: 3rem;
		
		.background {
			display: block;
			width: 100%;
			height: 245px;
			background-size: cover;
			background-position: center center;
			background-repeat: none;
			transition: 
				transform .5s, 
				box-shadow .5s, 
				left .5s, 
				width .5s;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			
			&:hover {
				transform: translateY(-6px);
				box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
				width: 106%;
				left: -3%;
			}
		}
		
		h3 {
			margin: 12px 0 6px 0;
			font-size: 22px;
			
			> a {
				color: inherit;
			}
		}
		
		i {
			margin-left: 5px;
			cursor: pointer;
		}
		
		.description-cta {
			margin-top: 15px;
		}
	}
	
	.passion:nth-child(3n+1){
		clear: left;
	}
	
	@media (min-width: $lg-min) {
		.passion:nth-child(3n+1){
			clear: none;
		}
	}
	
}



/* jQuery Modal */

.jquery-modal {
	z-index: 6;
}

.jqmodal {
	overflow: visible;
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 6;
  max-width: 650px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 15px;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -ms-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  box-shadow: 0 0 10px rgba(0,0,0,0.6);
  text-align: left;

	a.close-modal {
	  position: absolute;
	  top: -12.5px;
	  right: -12.5px;
	  display: block;
	  width: 30px;
	  height: 30px;
	  text-indent: -9999px;
	  background-size: contain;
	  background-repeat: no-repeat;
	  background-position: center center;
	  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==');
	
	}
	
	.image {
		width: 100%;
		height: 100%;
		min-height: 250px;
		background-size: cover;
		background-position: center center;
	}
	
	h3 {
		margin-top: 0;
	}
	
	ul {
		padding-left: 17px;
		
		li {
			margin-bottom: 5px;
		}
	}
	
}