* {
	font-family: 'Open Sans', sans-serif;
}

.clear {
	clear: both;
}

body {
	width: 100%;
	height: 100%;
	line-height: 1.42857;
}

a {
	cursor: pointer;
}

a.no-underline:hover {
	text-decoration: none;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.background-zoom {
	overflow: hidden;
  position: relative;
}

	$background-zoom-speed: 0.6s;
	$background-zoom-scale: 1.05;

	.background-zoom .background {
		position: absolute;
	    height: 100%;
	    width: 100%;
	    -moz-transition: all $background-zoom-speed;
	    -webkit-transition: all $background-zoom-speed;
	    transition: all $background-zoom-speed;
	    -moz-transform: scale(1,1);
	    -webkit-transform: scale(1,1);
	    transform: scale(1,1);
	    -moz-background-size: cover;
	    -webkit-background-size: cover;
	    background-size: cover;
	    z-index: -1;
	    background-position: center center;
		background-repeat: no-repeat;
	}
	
		.background-zoom:hover .background {
			-moz-transform: scale($background-zoom-scale,$background-zoom-scale);
		    -webkit-transform: scale($background-zoom-scale,$background-zoom-scale);
		    transform: scale($background-zoom-scale,$background-zoom-scale);
		}
		
.background-nozoom {
	overflow: hidden;
  position: relative;
}

	.background-nozoom .background {
		position: absolute;
    height: 100%;
    width: 100%;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    z-index: -1;
    background-position: center center;
		background-repeat: no-repeat;
	}

nav ul {
	padding-left: 0;
}

nav li {
	list-style: none;
}

/* Buttons and Form Elements */
	
.form-control {
	border-radius: 0;
	box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.5);
}

	.form-group input,
	.form-group select,
	#splash .form-group input::placeholder,
	#findHolidayBar .form-group input::placeholder {
		font-size: 13px;
		color: #000;
		font-weight: 600;
	}

.btn,
.bootstrap-styles .btn {
	border-radius: 0;
	font-weight: 600;
}

	#splash .btn.btn-action:hover,
	#findHolidayBar .btn.btn-action:hover,
	footer .btn.btn-action:hover {
		background-color: transparent;
		border-color: #fff;
		color: #fff !important; 
	}
	
	
//. Columns in Fifths i.e. 5 columns on a row

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  
  @media (min-width: $md-min) {
	  padding-right: 15px;
		padding-left: 15px;
	}
}

@media (max-width: $sm-max) {
	.col-xs-5ths:first-child,
	.col-sm-5ths:first-child,
	.col-md-5ths:first-child,
	.col-lg-5ths:first-child {
		padding-left: 15px;
	}
}

@media (max-width: $sm-max) {
	.col-xs-5ths:last-child,
	.col-sm-5ths:last-child,
	.col-md-5ths:last-child,
	.col-lg-5ths:last-child {
		padding-right: 15px;
	}
}


.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
      width: 20%;
      float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}