// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i');

// Bootstrap
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';
//@import '../../components/bootstrap-4.5.0/scss/bootstrap';

// JQuery Modal
@import '../../src/css/jquery.modal.css';

// Font Awesome
@import '../../bower_components/font-awesome/scss/font-awesome';

@import 'slideout';
@import 'fonts';
@import 'variables';
@import 'general';
@import 'header';
@import 'splash';
@import 'body';
@import 'footer';
@import 'international';

@import 'theme-skedaddle';
@import 'theme-adventures';
@import 'theme-biking';
@import 'theme-classicroad';
@import 'theme-cycling';

@import 'theme-family';