body.theme-biking {
	
	#splash select.form-control,
	#findHolidayBar select.form-control {
		background: url('/homepage/images/select-bg-biking.png') #fff center right no-repeat;
	}
	
		#splash select.form-control:hover,
		#findHolidayBar select.form-control:hover {
			background: url('/homepage/images/select-bg-hover.png') #fff center right no-repeat;
		}
	
	#ctas .cta h3 {
		background-color: rgba(51, 137, 199, 0.8); 
	} // #60ae2d
	
	#testimonials {
		background-color: #3489c6;
	}

}