header {
	padding-top: 20px;
	border-bottom-width: 8px;
	border-bottom-style: solid;
	background-color: #fff;
}

	header a,
	.bootstrap-styles header a {
		color: #232323;
		
	}
	
	.bootstrap-styles .list-inline {
		margin-left: 0;
	}

	header .logo {
		position: relative;
	}
	
		header .logo img {
			margin-bottom: 5px;
			
			@media (min-width: $sm-min) {
				margin-top: 7px;
				margin-bottom: 10px;
			}
			
			@media (min-width: $md-min) {
				margin-top: 0;
			}
		}
		
		header .logo .logo-name {
			font-size: 16px;
			font-size: 4.1vw;
			text-transform: uppercase;
			color: #bd0031; // Always Skedaddle Parent Colour
			
			@media (min-width: 550px) {
				font-size: 26.1px;
			}
			
			@media (min-width: $sm-min) {
				font-size: 24px;
			}
			
			@media (min-width: $md-min) {
				font-size: 26.1px;
			}
			
		}
		
		header .logo .tagline {
			font-family: Veneer;
			font-size: 5vw;
			
			@media (min-width: 420px) {
				font-size: 22.5px;
			}
			
			@media (min-width: $sm-min) {
				font-size: 20px;
				line-height: 0.9em;
				text-align: center;
			}
			
			@media (min-width: $md-min) {
				font-size: 22.5px;
			}
			
			@media (min-width: $md-min) {
				text-align: left;
			}
			
		}
		
	header a {
		cursor: pointer;
	}

	header .top-nav {
		font-size: 13px;
		line-height: 1.5em;
		margin-bottom: 20px;
		margin-top: 0;
		
		@media (min-width: $sm-min) {
			font-size: 12px;
		}
		
		@media (min-width: $md-min) {
			font-size: 13px;
		}
	}
	
		header .top-nav ul {
			margin: 0;
		}
	
			header .top-nav li {
				margin-left: 5px;
				float: right;
				
				@media (min-width: $sm-min) {
					float: none;	
				}
				
				@media (min-width: $md-min) {
					margin-left: 10px;
				}
			}
			
			header .top-nav li.login {
				text-align: center;
				
				@media (min-width: $sm-min) {
					text-align: left;
				}
				
				a {
					margin-right: 10px;
				}
				
				a:last-child {
					margin-right: 0;
				}
			}
			
				header .top-nav li.login img {
					margin: 0 auto;
					display: block;
					
					@media (min-width: $sm-min) {
						margin: 0 5px 0 0;
						display: inline-block;	
					}
				}
			
			header .top-nav li.mobile-toggle {
				display: inline-block;
				float: right;
				padding-right: 0;
			}
			
				
				header .top-nav .toggle-button {
					border: none;
					box-shadow: none;
					background: #fff;
					color: #aaa;
					font-size: 30px;
					top: 0;
					padding: 0;
					position: relative;
					display: block;
					z-index: 1;
					
					background-color: #eee;
					padding: 10px 12px;
					border-radius: 50%;
					font-size: 21px;
					top: -5px;
				}
				
					header .top-nav .toggle-button:hover {
						background-color: #bd0031;
					}
				
					header .top-nav .toggle-button i {
						color: #aaa;
					}
					
						header .top-nav .toggle-button:hover i {
							color: #fff;
						}
			
						
		header #flags {
			position: relative;
			
			.sub-menu {
				position: absolute;
				width: 120px;
				border: 1px solid #999;
				text-align: left;
				background-color: #fff;
				padding: 5px;
				box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
				top: -11px;
				text-align: left;
				
				@media (max-width: $md-min) {
					right: -6px;
				}
				
				@media (min-width: $md-min) {
					left: -6px;
				}
				
				li {
					padding: 5px;
					margin-left: 0;
					float: none;
					
					img {
						margin-right: 5px;
					}
					
					span {
						position: relative;
						top: 2px;
						display: inline-block;
					}
					
					button {
						background: none;
						border: none;
						padding: 0;
						
						&:hover {
							text-decoration: none;
						}
						
						&:hover span {
							color: #bd0031;
						}
						
					}
					
				}
			}
		}
	
	header .telephone-uk,
	header .telephone-int {
		color: #6c6c65;
	}
	
		header .telephone-uk {
			font-size: 14px;
			font-size: 4vw;
			margin-top: 5px;
			margin-bottom: 10px;
			
			@media (min-width: 510px) {
				font-size: 20px;
			}
			
			@media (min-width: $sm-min) {
				font-size: 16px;
				margin-top: 0;
				margin-bottom: 10px;
			}
			
			@media (min-width: $md-min) {
				font-size: 22px;
			}
			
			.openingtimes {
				font-size: 13px;
				padding-left: 10px;
			}
		}
		
		header .telephone-int {
			font-size: 13px;			
		}
		
			header .telephone-int .email {
				margin-left: 10px;
			}
			
			header .telephone-int a {
				color: #bd0031; // Always Skedaddle Parent Colour
			}
		
	header #search .form-control.submit {
		border: none;
		box-shadow: none;
		padding-left: 5px;
		padding-right: 5px;
	}
	
		header #search a.submit {
			position: relative;
			top: 1px;
		}
		
	header #search {
		position: relative;
		margin-bottom: 0;
	}
	
		header #search input[name=searchtext] {
			border: none;
			border-bottom: 1px solid #bababa;
			box-shadow: none;
			padding-left: 5px;
			padding-right: 5px;
			
			@media (min-width: $sm-min) {
				width: 140px;
			}
			
			@media (min-width: $md-min) {
				width: 181px;
			}
		}
		
			#search input::placeholder {
				font-weight: 400;
			}
	
	/* Search Results */
		
	header #searchresults {
		position: absolute;
		z-index: 100;
		top: 33px;
		left: 0;
		width: 300px;
		text-align: left;
		background: #fff;
		box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.3);
		
		@media (min-width: $sm-min) {
			width: 461px;
		}
		
		@media (min-width: $md-min) {
			width: 547px;
		}
		
		@media (min-width: $lg-min) {
			
		}
	}
	
		header #searchresults a {
			font-size: 13px;
		}
	
		header #searchresults table {
			width: 100%;
		}
		
			header #searchresults .search-holidays th,
			header #searchresults .search-other th {
				padding-bottom: 10px;
			}
			
		
		header #searchresults h2 {
			padding: 8px 12px;
			color: #fff;
			font-weight: 400;
			font-size: 18px;
			margin: 0;
		}
		
			header #searchresults .search-holidays h2 {
				background-color: #bd0031;
			}
			
			header #searchresults .search-other h2 {
				background-color: #aaa;
			}
	
		header #searchresults a {
			font-weight: 600;
		}
		
		header #searchresults img {
			width: 50px;
			height: 50px;
			max-width: inherit;
		}
		
		header #searchresults td {
			padding: 2px;
			line-height: 1.2em;
		}
		
		header #searchresults td.page, 
		header #searchresults td.query, 
		header #searchresults td.more {
			padding: 5px 10px;
		}
		
			 #searchresults td.news {
				 padding: 10px 10px;
			 }
		
		header #searchresults td.more {
			text-align: right;
			padding-right: 10px;
			padding-bottom: 15px;
		}
		
			header #searchresults td.more a {
				font-size: 18px;
			}
		
		header #searchresults td.col1 {
			padding: 4px 8px 4px 12px;
		}
		
		header #searchresults td.col2 {
			padding-left: 3px;
		}
		
		header #searchresults .item:first-child td {
			padding-top: 10px;
		}
		
		header #searchresults tr.hover td {
			cursor: pointer;
		}
		
		
	/* Primary Nav */
	
	header .primary-nav {
		
	}
	
	header .primary-nav ul {
		margin-top: 20px;
		margin-bottom: 0;
		margin-left: 0;
	}
	
		header .primary-nav li {
			margin-left: 0;
			margin-right: 0;
			padding: 0;
			font-size: 12px;
			padding-left: 0 !important;
			padding-right: 0 !important;
			
			@media (min-width: $sm-min) {
				
			}
			
			@media (min-width: $md-min) {
				font-size: 15px;
			}
			
			@media (min-width: $lg-min) {
				font-size: 16px;
			}
		}
		
		header .primary-nav > ul > li {
			padding-bottom: 20px;
		}
		
			header .primary-nav li.active > a {
				color: #bd0031; // Always Skedaddle Parent Colour
				font-weight: 600;
				font-size: 110%;
			}
	
		header .primary-nav li:after {
			content: "|";
			
			@media (min-width: $sm-min) {
				margin-left: 8px;
				margin-right: 4px;
			}
			
			@media (min-width: $md-min) {
				margin-left: 12px;
				margin-right: 8px;
			}
			
			@media (min-width: $lg-min) {
				margin-left: 24px;
				margin-right: 20px;
			}
			
		}
		
			header .primary-nav li:last-child:after {
				display: none;
			}
		
		header .primary-nav li.has-overlay > a {
			position: relative;
		}
			
/*
			header .primary-nav li.has-overlay > a:after {
				content: '\f0d7';
				font-family: FontAwesome;
				font-size: 10px;
				position: absolute;
				color: #7c7c7c;
				bottom: -1px;
				
				@media (min-width: $sm-min) {
					bottom: -1px;
					right: -8px;
				}
				
				@media (min-width: $md-min) {
					right: -11px;
				}
				
				@media (min-width: $lg-min) {

				}
			}
*/
			
	/* Top Overlay - from layout.css */
	
	header .primary-nav {
		position: relative;
	}
	
		header .primary-nav > ul {
			height: 40px;
		}
	
	header .overlay {
		position: absolute;
		z-index: 7;
		left: 0;
		display: none;
		background-color: #fff;
		
		@media (min-width: $sm-min) {
			width: 100%;
			top: 40px;
		}
		
	}
	
		header .overlay .padding {
			padding: 0;
			border-top-width: 8px;
			border-top-style: solid;
		}
		
			header .overlay .padding > .wrapper {
				padding-top: 10px;
				border-left-width: 1px;
				border-left-style: solid;
				border-left-color: #ddd;
				border-right-width: 1px;
				border-right-style: solid;
				border-right-color: #ddd;
				border-bottom-width: 1px;
				border-bottom-style: solid;
				border-bottom-color: #ddd;
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
			}
		
			header .overlay .level1 {
				margin-bottom: 10px;
			}
			
				header .overlay .level1 {
					border-right: 1px solid #ddd;
				}
				
					header .overlay .level1:last-child,
					header .overlay .level1.item-3 {
						border-right: none;
					}
			
				header .overlay .overlay-heading {
					font-size: 13px;
					font-weight: 600;
					margin-bottom: 10px;
					
					@media (min-width: $md-min) {
						font-size: 15px;
					}
				}
		
				header .overlay .level2 a {
					display: block;
					font-size: 12px;
					margin: 5px 0;
					font-weight: 600;
					
					@media (min-width: $md-min) {
						font-size: 13px;
					}
				}
	
	// HACK
	
	/*
	header #website6 {
		display: block !important;
	}
	*/
	
				
	// New Menu Overlay
	header .overlay.overlay-menu {
		padding: 1rem;
		box-shadow: 0px 13px 12px 0 rgba(0, 0, 0, 0.5);
		
		.menu-column {
			border-right: 1px solid #ccc;
			
			&:last-child {
				border-right: none;
			}
			
			// Limit height of columns
			@media (max-height: 700px) {
				max-height: 50vh;
				overflow: auto;
			}
			
		}
		
		.menu-title {
			font-size: 20px;
			font-style: italic;
			margin-bottom: 10px;
			
			@media (min-width: $md-min) {
				font-size: 24px;
			}
		}
		
		.menu-image {
			margin-bottom: 10px;
		}
		
		.menu-list {
			margin-bottom: 10px;
			
			ul {
				margin: 10px 0;
				
				li:after {
					display: none;
				}
				
				li {
					margin: 8px 0;
					font-size: 10px;
					
					@media (min-width: $md-min) {
						font-size: 12px;
					}
				}
				
			}
			
		}
		
		.menu-text {
			color: inherit;
			line-height: 1.42857;
		}
		
		.menu-list-title {
			font-size: 13px;
			
			@media (min-width: $md-min) {
				font-size: 16px;
			}
		}
		
		.menu-grades {
			border: none;
			padding-bottom: 2px;
			padding-top: 4px;
			margin-bottom: 15px;
			
			label {
				float: left;
			}
			
			.help {
				margin-left: 4px;
				float: left;
			}
			
			table {
				clear: both;
				width: 90%;
				table-layout: auto;
				margin-top: 10px;
				
				@media (min-width: $md-min) {
					width: 70%;
				}
				
				.bar {
					display: block;
					width: 90%;
					height: 10px;
					background-color: #ccc;
				}
				
				.bar1 {
					height: 10px;
				}
				
					.bar0 {
						height: 10px;
					}
					
					.bar1 {
						height: 17px;
					}
					
					.bar2 {
						height: 24px;
					}
					
					.bar3 {
						height: 31px;
					}
					
					.bar4 {
						height: 38px;
					}
				
					.bar5 {
						height: 45px;
					}
				
				td {
					vertical-align: bottom
				}
				
				th {
					padding-top: 2px;
				}
				
				tfoot {
					th {
						font-size: 12px;
						font-weight: 400;
						text-align: center;
					}
				}
			}
			
		}
		
	}
	
	/* Staff Nav */
	
	.staffnav,
	.customernav {
		background-color: #eee;
		padding: 8px 5px;
		margin-bottom: 20px;
		
		> ul.list-inline {
			margin-bottom: 0;
			
			li {
				border-right: 1px solid #ddd;
				padding-left: 0;
				margin-left: 0;
				padding-right: 10px;
				margin-right: 10px;
			}
			
			li:first-child {
				border-right: none;
				padding-left: 10px;
			}
		}
		
		.icon {
			color: #aaa;
		}
		
	}
	