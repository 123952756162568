// This is used for both /homepage and rest of site (/)

#internationalModal {
	
	.modal-content {
		background: url('/images/international-bg.jpg') center center no-repeat; //Change this
		background-size: cover;
	}
	
	.btn {
		i {
			margin-left: 5px;
		}
	}
	
	.btn-primary {
		background-color: #bd0031;
		border-color: #bd0031;
	}
	
	.btn-nav {
		background-color: transparent;
		color: #fff;
		font-weight: 400;
		text-shadow: 0px 1px 7px black;
	}
	
	.modal-header {
		border-bottom: none;
	}
	
	.modal-body {
		text-align: center;
		padding: 0;
		width: 100%;
		
		.inner {
			padding: 10% 0 4% 0;
		}
		
		img {
			width: 50%;
			height: auto;
		}
		
		.strip {
			background: rgba(255, 255, 255, 0.7);
			padding: 15px 0;
			margin: 15px 0 30px 0;
			
			h3 {
				font-family: $veneer;
				font-size: 34px;
				color: #2e2e2e;
			}
			
			p {
				font-size: 18px;
				font-weight: 600;
				color: #2e2e2e;
			}
		}
		
		.btn {
			font-size: 18px;
			padding: 15px 20px;
		}
		
	}
	
	.modal-footer {
		background: rgba(255, 255, 255, 0.9);
		
		.form-group {
			padding-top: 5px;
			margin: 0;
			
			label {
				margin-bottom: 0;
				margin-top: 0;
				padding-left: 0;
				font-weight: 600;
			}
			
			input[type=checkbox] {
				float: none;
				display: inline-block;
				position: relative;
				top: -3px;
				margin-right: 5px;
				margin-left: 5px;
				margin-top: 0;
			}
			
		}
		
	}
	
}


#internationalBanner {
	text-align: center;
	background-color: #bd0031;
	color: #fff;
	display: none;
	
	button {
		background-color: transparent;
		font-weight: 600;
		font-style: italic;
		font-size: 14px;
		border: none;
		padding-top: 15px;
		padding-bottom: 15px;
		
		&:hover {
			text-decoration: underline;
		}
		
		&:focus {
			outline: none;
		}
	}
	
}