body.theme-skedaddle {
	
	#splash select.form-control,
	#findHolidayBar select.form-control {
		background: url('/homepage/images/select-bg.png') #fff center right no-repeat;
	}
	
		#splash select.form-control:hover,
		#findHolidayBar select.form-control:hover {
			background: url('/homepage/images/select-bg-hover.png') #fff center right no-repeat;
		}
	
	#ctas .cta h2 {
		background-color: rgba(204, 34, 77, 0.8); 
	} // #cb224d
	
	#testimonials {
		background-color: #cb224d;
	}

}