$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;

$xs-max: $sm-min - 1px;
$sm-max: $md-min - 1px;
$md-max: $lg-min - 1px;


@media (min-width: $sm-min) {
	
}

@media (min-width: $md-min) {
	
}

@media (min-width: $lg-min) {
	
}


@media (max-width: $xs-max){
	clear: both !important;
}

@media (max-width: $sm-max) and (min-width: $sm-min){
	clear: both !important;
}

@media (max-width: $md-max) and (min-width: $md-min){
	clear: both !important;
}

@media (min-width: $md-min){
	clear: both !important;
}